<template>
  <div id="homeProductComponent" style="padding-top: 20px; padding-bottom: 8px;">
    <div class="date">

      <div style="display: flex; justify-content: center; align-items: center; margin-top: 4px;">
        <div style="font-size: 34px; font-weight: bold; line-height: 40px;">{{ data.cycleDay }}</div>
        <div style="font-size: 12px; line-height: 12px; margin-left: 4px; margin-top: 13px;">{{ '天' }}</div>
      </div>
      <div style="font-size: 12px; line-height: 12px; text-align: center;">{{ '养殖天数' }}</div>
      <div
        style="display: flex; color: #A1A1A1; font-size: 14px; line-height: 14px;margin-top: 8px; justify-content: center;"
        @click="onClickShow" v-if="data.steps > 0">
        <div>
          {{ '行走总步数:' }}
        </div>
        <div style="margin-left: 4px;">
          {{ data.steps }}
        </div>
        <div style="margin-left: 4px;">
          {{ '>' }}
        </div>
      </div>
    </div>

    <div class="info">
      <div class="title">
        <span> {{ '基本信息' }}</span>
      </div>

      <div class="infoContainer">
        <div style="display: flex; margin-top: 4px;">
          出品企业
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ data.supplierName }}</div>
        </div>
        <div style="display: flex; margin-top: 4px;">
          商品规格
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ data.productSpec }}</div>
        </div>
        <div style="display: flex; margin-top: 4px;">
          产
          <div style="width: 28px;" />
          地
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ '文昌市' }}</div>
        </div>
      </div>
    </div>


    <div class="blockchainCertificate">
      <div class="title">
        <span>{{ '区块链证书展示' }}</span>
      </div>

      <div class="blockchainCertificateContanier">
        <div style="display: flex; margin-top: 4px; align-items: center; color: #222222;">
          <img src="../assets/images/certificate.png" alt="" style="width: 80px; height: 60px;">

          <div style="flex: 1; margin-left: 12px;">
            区块链认证证书
          </div>
          <div style="margin-left: 8px;color:#69b160;" @click="onClickShowOrderContractImage">{{ '点击查看 >' }}</div>
        </div>

        <div style="flex: 1; color: #A1A1A1;">
          <div style="display: flex; margin-top: 12px;">
            认证机构
            <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ '一天膳事区块链' }}</div>
          </div>
          <!--      <div style="display: flex; margin-top: 12px;">-->
          <!--        所在区块-->
          <!--        <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ '' }}</div>-->
          <!--      </div>-->
          <div style="display: flex; margin-top: 12px;">
            <div style="width: 56px;">唯一标识</div>
            <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right; word-break: break-all;">{{
              data.manufactureOrderContractId }}</div>
          </div>
          <div style="display: flex; margin-top: 12px;">
            认证时间
            <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{
              data.manufactureOrderDigitalIdentityReportTime }}</div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "HomeProductComponent",
  props: [
    'data'
  ],
  data() {
    return {}
  },
  methods: {
    onClickShowOrderContractImage() {
      this.$parent.onClickShowOrderContractImage();
    }
  }
}
</script>

<style lang="less" scoped>
#homeProductComponent {
  .date {
    background: url('../assets/images/bg-date-color.png')no-repeat center / cover;
    margin: 0 10px;
    padding-top: 6px;
    padding-bottom: 30px;
    margin-bottom: -13px;
    position: relative;
    color: #fff;
    border-radius: 8px 8px 0 0;
    // padding: 2px 0 10px 0;
    // background: #48ca59;

    // position: relative;

    // &::after {
    //   content: "";
    //   display: block;
    //   border-left: 10px solid transparent;
    //   border-right: 10px solid transparent;
    //   border-top: 10px solid #48ca59;
    //   position: absolute;
    //   width: 0px;
    //   left: 0;
    //   right: 0;
    //   bottom: -9px;
    //   margin: auto;
    // }
  }

  .info {
    background: #fff;
    border-radius: 8px;

    .infoContainer {
      flex: 1;
      color: #A1A1A1;
      padding: 0px 10px 10px 10px;
    }
  }
}

.blockchainCertificate {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 16px;

  .blockchainCertificateContanier {
    padding: 0px 10px 10px 10px;
  }
}

.title {
  display: flex;
  font-size: 14px;
  color: #222222;
  font-weight: bold;
  margin-bottom: 8px;
  background: linear-gradient(to bottom, #e8f4e8, #f9fcf8);
  padding: 10px 10px 3px 10px;
  border-radius: 8px;

  span {
    display: inline-block;
    background: linear-gradient(to bottom, transparent, transparent 70%, #cfebd3 71%);
  }
}
</style>