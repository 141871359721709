<template>
  <div id="ctBusinessInfoComponent" style="padding-top: 8px;">
    <div class="box">
      <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold; margin-bottom: 8px;">
        <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;" />
        <div style="line-height: 18px; margin-left: 5px;">{{ business ? business.supplierName : '' }}</div>
      </div>

      <div style="flex: 1; color: #A1A1A1;">
        <div style="display: flex; margin-top: 4px;">
          地址
          <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ business ? business.address : ''
          }}</div>
        </div>
      </div>
    </div>
    <div style="display: flex; color: #A1A1A1; margin-top: 4px;" v-if="business">
      <div style="display:flex; flex: 1; color: #222222; margin-left: 8px; align-items: center; place-content: center;">
        <div v-for="(item, index) in business.trustList" :key="index" style="display: flex;"
          :style="{ marginLeft: index !== 0 ? `10px` : `0` }">
          <img src="../assets/images/icon_trust.png" style="width: 18px; height: 18px;" />
          <div style="line-height: 18px;">{{ '认真' }}</div>
        </div>
      </div>
    </div>
    <!--      <div style="margin: 8px 16px 8px 16px; text-align: center;">-->
    <!--        {{ business.introduction }}-->
    <!--      </div>-->
    <div style="font-size: 14px;">
      <div class="box">
        <div style="color: #222222; font-weight: bold;">
          <div style="display: flex;">
            <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
            <div style="line-height: 18px; margin-left: 5px;">企业简介</div>
          </div>
          <van-swipe :autoplay="3000" indicator-color="#34AC40" style="margin-top: 8px;" >
            <van-swipe-item v-for="(image, index) in (business ? business.supplierDescriptionImages : [])" :key="index">
              <img v-lazy="image" style="width: 100%; height: calc((100vw - 24px) * 0.64); object-fit: fill;" />
            </van-swipe-item>
          </van-swipe>

          <div style="color: #222222; font-size: 14px; margin-top: 8px; font-weight: normal;">{{
            business ? business.supplierDescription : ''
          }}
          </div>
        </div>
      </div>
      <div class="box">
        <div style="color: #222222; font-weight: bold; margin-top: 8px;" v-if="business">
          <div style="display: flex;">
            <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
            <div style="line-height: 18px; margin-left: 5px;">企业荣誉</div>
          </div>
          <div v-for="(item, index) in business.honerImgUrls" :key="index">
            <van-image width="100%" :src="item" style="margin-top: 8px;" />
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CTBusinessInfoComponent",
  props: [
    'business'
  ],
  data() {
    return {
      data: {
        title: '-',
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.box {
  background: #fff;
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;

  &+.box {
    margin-top: 10px;
  }

}
</style>